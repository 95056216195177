.smartbanner {
  position: absolute;
  top: 0;
  left: 0;
  overflow-x: hidden;
  width: 100%;
  height: 84px;
  background: #f3f3f3;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: Helvetica, sans, sans-serif;
  /** Android styles **/ }
  .smartbanner__exit {
    display: block;
    margin: 0;
    width: 12px;
    height: 12px;
    border: 0;
    text-align: center;
    margin-left: 9px;
    margin-right: 9px;
    flex-shrink: 0; }
  .smartbanner__icon {
    top: 10px;
    left: 30px;
    width: 64px;
    height: 64px;
    border-radius: 15px;
    background-size: 64px 64px;
    margin-right: 9px;
    flex-shrink: 0; }
  .smartbanner__info {
    display: flex;
    height: 64px;
    justify-content: space-between;
    width: auto;
    align-items: center;
    flex-grow: 2;
    color: #000; }
    .smartbanner__info div {
      flex-shrink: 1; }
    .smartbanner__info__title {
      font-weight: bold;
      font-size: 14px;
      line-height: 19px; }
    .smartbanner__info__author {
      font-weight: bold;
      font-size: 12px;
      line-height: 16px; }
    .smartbanner__info__author, .smartbanner__info__price {
      font-size: 12px; }
  .smartbanner__button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    padding: 0 10px;
    min-width: 10%;
    height: 28px;
    background: #308DDF;
    border-radius: 3.75px;
    color: white;
    font-weight: bold;
    font-size: 9.75px;
    text-align: center;
    text-decoration: none;
    text-shadow: none;
    margin-left: 9px;
    margin-right: 9px; }
    .smartbanner__button__label {
      text-align: center; }
  .smartbanner.smartbanner--android {
    background: #3d3d3d; }
    .smartbanner.smartbanner--android .smartbanner__exit {
      left: 6px;
      margin-right: 7px;
      width: 17px;
      height: 17px;
      border-radius: 14px;
      background: #1c1e21;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.8) inset, 0 1px 1px rgba(255, 255, 255, 0.3);
      color: #b1b1b3;
      font-family: 'ArialRoundedMTBold', Arial;
      font-size: 20px;
      -webkit-font-smoothing: subpixel-antialiased;
      line-height: 17px;
      text-shadow: 0 1px 1px #000; }
      .smartbanner.smartbanner--android .smartbanner__exit::before, .smartbanner.smartbanner--android .smartbanner__exit::after {
        top: 3px;
        left: 8px;
        width: 2px;
        height: 11px;
        background: #b1b1b3; }
      .smartbanner.smartbanner--android .smartbanner__exit:active, .smartbanner.smartbanner--android .smartbanner__exit:hover {
        color: #eee; }
    .smartbanner.smartbanner--android .smartbanner__icon {
      background-color: transparent;
      box-shadow: none; }
    .smartbanner.smartbanner--android .smartbanner__info {
      color: #ccc;
      text-shadow: 0 1px 2px #000; }
      .smartbanner.smartbanner--android .smartbanner__info__title {
        color: #fff;
        font-weight: bold;
        font-size: 14px;
        line-height: 19px; }
      .smartbanner.smartbanner--android .smartbanner__info__author, .smartbanner.smartbanner--android .smartbanner__info__price {
        color: #fff; }
    .smartbanner.smartbanner--android .smartbanner__button {
      top: 30px;
      right: 5px;
      padding: 0;
      min-width: 12%;
      border-radius: 0;
      background: none;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10px;
      min-width: 10%;
      height: 28px;
      background: #308DDF;
      border-radius: 3.75px;
      color: white;
      font-weight: bold;
      font-size: 9.75px; }
  @media screen and (max-width: 320px) {
    .smartbanner__info {
      left: 50px; }
    .smartbanner__button {
      right: 5px; } }
